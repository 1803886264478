import styled from 'styled-components';

const VendorDirectoryStyles = styled.div`
    .Accord__container {
        margin: 0 auto;
        padding: 30px;
        max-width: 1060px;
    }

    h1 {
        font-weight: 800;
        font-size: 35px;
        line-height: 48px;
        color: ${props => props.theme.white};

        @media (max-width: 786px) {
            font-size: 30px;
            font-weight: 800;
            line-height: 41px;
        }
    }

    .BecomeVendor__message {
        font-size: 16px;
        line-height: 200%;
        text-align: center;
        text-decoration: none;
        color: ${props => props.theme.white};
        max-width: 880px;
    }

    .BecomeVendor__container {
        align-items: center;
        background: ${props => props.theme.orange};
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 80px 20px;
    }

    .BecomeVendor__message {
        padding-bottom: 22px;
    }

    .JoinNow__button {
        background: ${props => props.theme.white};
        border-radius: 3px;
        border: 3px solid ${props => props.theme.white};
        box-sizing: border-box;
        color: ${props => props.theme.orange};
        font-size: 14px;
        font-weight: 800;
        line-height: 19px;
        padding: 13px 44px;
        text-align: center;
        text-transform: uppercase;
        transition: 0.2s ease-in-out;
        z-index: 100;

        &:hover,
        &:active {
            background: transparent;
            color: ${props => props.theme.white};
        }
    }

    .BecomeVendor__message a:hover {
        color: white;
    }
`;

export default VendorDirectoryStyles;
