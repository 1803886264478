import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Title from '../components/styles/title';

// components
import VendorDirectoryAccordion from '../components/vendor-directory-accordion';

// styled components
import VendorDirectoryStyles from '../components/styles/vendor-directory-style';

// data
import mediaPackage from '../data/IOLCF-Media-Package-2022.pdf';

const VendorDirectory = () => (
    <Layout>
        <SEO title="Vendor Directory" />
        <Title>Vendor Directory</Title>

        <VendorDirectoryStyles>
            <div className="Accord__container">
                <VendorDirectoryAccordion />
            </div>
            <div className="BecomeVendor__container">
                <h1>Become a Vendor</h1>
                <p className="BecomeVendor__message">
                    To learn more, reach out to{' '}
                    <a href="mailto:mail@iolcf.com">mail@iolcf.com</a>.
                </p>
            </div>
        </VendorDirectoryStyles>
    </Layout>
);

export default VendorDirectory;
