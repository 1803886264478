/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slider from 'react-slick';

// styled components
import VendorPartnerLogosStyles from './styles/vendor-partner-logos-styles';

// slick css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const VendorPartnerLogos = () => {
    const settings = {
        autoplay: true,
        autoplaySpeed: 0,
        dots: false,
        infinite: true,
        pauseOnHover: true,
        slidesToScroll: 1,
        slidesToShow: 6,
        useTransform: false,
        speed: 2000,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    infinite: true,
                    slidesToScroll: 1,
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 2,
                },
            },
        ],
    };

    const data = useStaticQuery(graphql`
        query {
            trane: file(relativePath: { eq: "trane-logo.png" }) {
                ...fluidImage
            }
            proliant: file(relativePath: { eq: "proliant-logo.jpg" }) {
                ...fluidImage
            }
            rockbot: file(relativePath: { eq: "rockbot-logo.png" }) {
                ...fluidImage
            }
            raydiant: file(relativePath: { eq: "raydiant-logo.png" }) {
                ...fluidImage
            }
            restaurant365: file(
                relativePath: { eq: "restaurant_365_logo.png" }
            ) {
                ...fluidImage
            }
            franbiz: file(relativePath: { eq: "franbiz_logo.png" }) {
                ...fluidImage
            }
            budderfly: file(relativePath: { eq: "budderfly_logo.png" }) {
                ...fluidImage
            }
            intrepid: file(relativePath: { eq: "intrepid_logo.png" }) {
                ...fluidImage
            }
            thehealthbenefitalliance: file(
                relativePath: { eq: "the-health-benefit-alliance-logo.png" }
            ) {
                ...fluidImage
            }
            boa: file(relativePath: { eq: "bank-of-america-logo.png" }) {
                ...fluidImage
            }
            pepsi: file(relativePath: { eq: "Pepsi-logo.jpg" }) {
                ...fluidImage
            }
            upShow: file(relativePath: { eq: "upshow-logo.png" }) {
                ...fluidImage
            }
            titanium: file(relativePath: { eq: "Titanium-logo.jpg" }) {
                ...fluidImage
            }
            Leasecake: file(relativePath: { eq: "LeasecakeLogo.png" }) {
                ...fluidImage
            }
            WS: file(relativePath: { eq: "WSLogo.png" }) {
                ...fluidImage
            }
            FDC: file(relativePath: { eq: "FDC.jpg" }) {
                ...fluidImage
            }
            irhCap: file(relativePath: { eq: "irh-Logo.jpg" }) {
                ...fluidImage
            }
            elevanta: file(relativePath: { eq: "elevanta.png" }) {
                ...fluidImage
            }
            sib: file(relativePath: { eq: "sib.png" }) {
                ...fluidImage
            }
            xlt: file(relativePath: { eq: "xlt.png" }) {
                ...fluidImage
            }
            usi: file(relativePath: { eq: "usi.jpg" }) {
                ...fluidImage
            }
            emcentrix: file(relativePath: { eq: "EmCentrixLogo.png" }) {
                ...fluidImage
            }
            reachify: file(relativePath: { eq: "reachify-logo.png" }) {
                ...fluidImage
            }
            prepwizard: file(relativePath: { eq: "prepwizard_logo.jpg" }) {
                ...fluidImage
            }
            hourWork: file(relativePath: { eq: "hour-work-logo.png" }) {
                ...fluidImage
            }
            paylocity: file(relativePath: { eq: "paylocity-logo.png" }) {
                ...fluidImage
            }
            onaroll: file(relativePath: { eq: "onaroll_logo.jpeg" }) {
                ...fluidImage
            }
            guideline: file(relativePath: { eq: "guideline-logo.png" }) {
                ...fluidImage
            }
        }
    `);

    const styles = {
        img: {
            objectFit: 'contain',
            width: '100%',
            maxHeight: '150px',
        },
    };

    // add new vendor logos here into to 3rd position of the slider

    return (
        <VendorPartnerLogosStyles>
            <h1>Our Vendor Partners</h1>
            <Slider {...settings}>
                <div className="Logo__container">
                    <a
                        href="https://caesars.raydiant.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.raydiant.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.restaurant365.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.restaurant365.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.trane.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.trane.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.proliant.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.proliant.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.rockbot.com/littlecaesars"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.rockbot.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.franbiznetwork.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.franbiz.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.budderfly.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.budderfly.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://intrepiddirect.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.intrepid.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.guideline.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.guideline.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.onaroll.co/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.onaroll.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://try.hourwork.com/LChourwork"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.hourWork.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.paylocity.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.paylocity.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://ep6ix.com/iolcf/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={
                                data.thehealthbenefitalliance.childImageSharp
                                    .fluid
                            }
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://bankofamerica.com/GeneralFranchise"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.boa.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.pepsico.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.pepsi.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://prep-wizard.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.prepwizard.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="http://www.upshow.tv/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.upShow.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="http://www.0feesolutions.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.titanium.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://try.leasecake.com/tenants/?utm_source=associations&utm_medium=website&utm_campaign=IOLCF"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.Leasecake.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.irhcapital.com/littlecaesars"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.irhCap.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.usi.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.usi.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="http://www.xltovens.com/IOLCF"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.xlt.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="http://www.elevantahealth.com/iolcf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.elevanta.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.aboutsib.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.sib.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://www.emcentrix.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.emcentrix.childImageSharp.fluid}
                        />
                    </a>
                </div>
                <div className="Logo__container">
                    <a
                        href="https://get.reachify.io/iolcf/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            imgStyle={styles.img}
                            fluid={data.reachify.childImageSharp.fluid}
                        />
                    </a>
                </div>
            </Slider>
        </VendorPartnerLogosStyles>
    );
};

export default VendorPartnerLogos;

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`;
